//import logo from './logo.svg';
import './App.css';
import {BubbleAnimation} from './BubbleAnimation.tsx'


function App() {
  return (
    <div className="App-header">
      
        <div className='mm'>
            <img src="sgoat-logo.png" className="App-logo2" alt="Goat1" />
            <p>
              sGOAT : <a className="App-link" href="https://ftmscan.com/token/0x43F9a13675e352154f745d6402E853FECC388aA5" target="_blank" rel="noopener noreferrer">0x43F9a13675e352154f745d6402E853FECC388aA5</a>  
            </p>
            <a className="App-link" href="https://dexscreener.com/fantom/0x6f60e79e3be2009f1c8b6786b761f8d3ee67d18f" target="_blank" rel="noopener noreferrer">BUY sGOAT</a>
        </div>

        <div>
          <img src="Goat1.jpg" className="App-logo" alt="Goat1" />
          <img src="goat2.jpg" className="App-logo" alt="Goat2" />
          <img src="goat3.jpg" className="App-logo" alt="Goat3" />
          <img src="Goat4.jpg" className="App-logo" alt="Goat4" />
        </div>
        
      <BubbleAnimation />
    </div>
  );
}

export default App;
